@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: "poppins";
    font-size: 22px;
    color: #fff;
}

body {
    min-height: 100vh;
    background-color: rgb(0, 0, 0);
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
  padding: 1rem 0.5rem;
}

section {
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgba(1, 119, 143, 0.458);
    border-radius: 8px;
    box-shadow: 0px 0px 25px 25px rgba(1, 119, 143, 0.458);
    
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
   
}

a, a:visited {
    color: #fff;
}

input[type="text"],
input[type="password"]

 {
  font-family:"poppins";
  font-size: 22px;
  padding: 0.25rem;
  color: white;
  outline: none;
  height: 50px;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
}

label{
margin-top: 0.9rem;
font-size: 20px;
}
h1 {
  font-size: 20px;
}

button {
  padding: 0.5rem;
  border-radius: 10px;
  width: 100%;
  max-width: 150px;
  margin: 1rem auto;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  background-color: white;
  color: black;
  border: none;
  box-shadow: 0px 0px 5px 5px white;
  cursor: pointer;
  transition: 0.2s all ease;
  position: relative;
  top: 30px;
 

}

button:disabled {
  opacity: 0.3;;
}
button:hover {
  box-shadow: 0px 0px 10px 5px white;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}


.line {
    display: inline-block;
}

.valid {
  color: limegreen;
  position: relative;
  right: -120px;
  top: 50px;
  
}

.invalid {
  color: red;
  position: relative;
  right: -120px;
  top: 50px;
}
.valid2 {
  color: limegreen;
  position: relative;
  right: -80px;
  top: 50px;
  
}

.invalid2 {
  color: red;
  position: relative;
  right: -80px;
  top: 50px;
}